
import { Component, Vue } from "vue-property-decorator";
import json from '../../../package.json'
import { TipTap } from '@/mixins/TipTap';
import { mixins } from "vue-class-component";
import { ROOT_ACTIONS } from "@/store/actions";
@Component
export default class Index extends mixins(TipTap) {
  loading = {
    icon: false,
    logo: false,
  };

  get version() {
    return json.version;
  }



  template = '';

  mounted() {
    this.template = localStorage.getItem('requestTemplate') || '';
  }

  saveRequestTemplate() {
    localStorage.setItem('requestTemplate', this.template);
    this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
      color: 'success',
      text: 'Template opgeslagen'
    })
  }
}
